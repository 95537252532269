import Keycloak from "keycloak-js";
import {kcInitOptions} from './kc.options';
import axios from 'axios';

const _kc = new Keycloak(kcInitOptions);

 export interface UserModel{
  id?: number | undefined;
  firstName?: string | undefined;
  lastName?:string
  email?:string | undefined;
  picture ?: string | undefined;
  username?: string | undefined;
}

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {

  _kc.init({
    onLoad: kcInitOptions.onLoad
  }).then((authenticated) => {
      if (!authenticated) {
        console.log("user is not authenticated..!");
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const doLogin = () => _kc.login({ locale: 'pt_BR' });

const doUpdatePassword = () => _kc.login({action: "UPDATE_PASSWORD"});

const doLogout = () => {
  axios.request({
    method: 'GET',
    url: `${window.location.origin}/grafana/logout`,
    maxRedirects: 0,
    withCredentials: true,
    transformRequest: [function (data, headers) {
      if(headers?.Authorization) {
        delete headers['Authorization'];
      }
      return data;
    }]
  }).catch(() => {
    //do nothing
  }).finally(() => {
    _kc.logout();
  });
}

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const getName = () => _kc.tokenParsed?.name;

const getId = () => _kc.tokenParsed?.giaUserId;

const getTokenParsed = () => _kc.tokenParsed;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const getUserInfo = () => {
  return axios.get<UserModel>('/api/user/info');
};

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  doUpdatePassword,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  getName,
  getId,
  getTokenParsed,
  getUserInfo,
  hasRole,
};

export default UserService;
