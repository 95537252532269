import { ElementType, FC } from "react";
import styles from "./gia-forms-components.module.scss";
import {FormControl} from "react-bootstrap";
import { UseFormRegisterReturn } from "react-hook-form";

interface GiaBaseProps {
  title?: string | undefined;
  type?: string | undefined;
  step?: number | undefined;
  registerName?: UseFormRegisterReturn;
  placeholder?: string | undefined;
  className?: string | undefined;
  height?: number | undefined;
  disabled?: boolean | undefined;
  isInvalid?: boolean | undefined;
  isValid?: boolean | undefined;
  isInvalidText?: string | undefined;
  showMessage?: boolean | undefined ;
  min?: number | undefined;
  max?: number | undefined;
  defaultValue?: string | undefined;
  id?: string | undefined;
  autocomplete?: string | undefined;
  onChange?: (event) => void;
  required?: boolean;
}

interface GiaTextFieldProps extends GiaBaseProps {
  as?: 'input' | 'textarea' | ElementType;
  rows?: number | undefined;
}

const GiaFormsComponents = () => { };


export const GiaTextField: FC<GiaTextFieldProps> = (props) => {
  return (
    <div className={`${styles.giaTextField}${props.className?' '+props.className:''}`}>
      <div className={`label${props.required?' required':''}`} title={props.title}>{props.title}</div>
      <FormControl {...props.registerName}
                   type={props.type}
                   step={props.step}
                   max={props.max}
                   defaultValue={props.defaultValue}
                   placeholder={props.placeholder}
                   height={props.height}
                   isInvalid={props.isInvalid}
                   isValid={props.isValid}
                   onChange={props.onChange}
                   autoComplete={props.autocomplete}
                   as={props.as}
                   rows={props.rows}
                   disabled={props.disabled}
                   />
      {<div className={`${styles.feedbackMessage} gia-message-error`}>
        {props.showMessage && <span>{props.isInvalidText}</span>}
        </div>}
    </div>
  );
};


export default GiaFormsComponents;
