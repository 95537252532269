import React from 'react';
import { useEffect } from 'react'
import './App.scss';
import {Outlet} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { usePostHog } from 'posthog-js/react'
import UserService from './modules/auth/UserService';

function App() {

  const posthog = usePostHog()
  const user = UserService;

  useEffect(() => {
    if (user) {
        // Identify sends an event, so you want may want to limit how often you call it
        posthog?.identify(user.getId(), {
            email: user.getUsername(), 
        })
    }
}, [posthog, user, user.getUsername])

  return (
    <div className="App">
      <Outlet />
      <ToastContainer/>
    </div>
  );
}

export default App;
