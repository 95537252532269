export enum Roles {
  OVERVIEW_VIEWER = 'OVERVIEW_VIEWER',
  MANAGED_PLANTS_VIEWER = 'MANAGED_PLANTS_VIEWER',
  DASHBOARD_VIEWER = 'DASHBOARD_VIEWER',
  PLANT_EDITOR = 'PLANT_EDITOR',
  USER_EDITOR = 'USER_EDITOR',
  GROUP_EDITOR = 'GROUP_EDITOR',
  PERSONA_CREDENTIAL_EDITOR = 'PERSONA_CREDENTIAL_EDITOR',
  GROWATT_END_USER_EDITOR = 'GROWATT_END_USER_EDITOR',
  ALERT_EDITOR = 'ALERT_EDITOR',
  ROBBU_GROUP_EDITOR = 'ROBBU_GROUP_EDITOR',
  SCHEDULER_MESSAGE_EDITOR = 'SCHEDULER_MESSAGE_EDITOR',
  PERSONA_EDITOR = 'PERSONA_EDITOR',
  CUSTOMER_EDITOR = 'CUSTOMER_EDITOR',
  PLANTS_SHUTDOWN = 'PLANTS_SHUTDOWN',
  PLANTS_POWER_UP = 'PLANTS_POWER_UP',
  PLANT_DEVICE_DELETE = 'PLANT_DEVICE_DELETE',
  MANAGED_PLANTS_ALLOW_SHUTDOWN = 'MANAGED_PLANTS_ALLOW_SHUTDOWN'
}

export const roleTranslations: Record<Roles, string> = {
  [Roles.OVERVIEW_VIEWER]: 'Visualizador de Visão Geral',
  [Roles.MANAGED_PLANTS_VIEWER]: 'Visualizador de Usinas Gerenciadas',
  [Roles.DASHBOARD_VIEWER]: 'Visualizador de Dashboard',
  [Roles.PLANT_EDITOR]: 'Editor de Usina',
  [Roles.USER_EDITOR]: 'Editor de Usuário',
  [Roles.GROUP_EDITOR]: 'Editor de Grupo',
  [Roles.PERSONA_CREDENTIAL_EDITOR]: 'Editor de Credenciais de Pessoa',
  [Roles.GROWATT_END_USER_EDITOR]: 'Editor de Usuário Growatt',
  [Roles.ALERT_EDITOR]: 'Editor de Alertas',
  [Roles.ROBBU_GROUP_EDITOR]: 'Editor de Grupos de Mensagens',
  [Roles.SCHEDULER_MESSAGE_EDITOR]: 'Editor de Agendamentos',
  [Roles.PERSONA_EDITOR]: 'Editor de Persona',
  [Roles.CUSTOMER_EDITOR]: 'Editor de Clientes',
  [Roles.PLANTS_SHUTDOWN]: 'Desligamento de Usinas',
  [Roles.PLANTS_POWER_UP]: 'Religamento de Usinas',
  [Roles.PLANT_DEVICE_DELETE]: 'Remover dispositivos de usina',
  [Roles.MANAGED_PLANTS_ALLOW_SHUTDOWN]: 'Permissão de shutdown em edição de usinas'
};
