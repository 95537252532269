import React, {FC, useEffect, useState} from 'react';
import styles from './plant.module.scss';
import {useParams} from 'react-router-dom';
import EmissionsReduction from '../../components/emissions-reduction/emissions-reduction';
import EnergyGeneratedAtPlant from '../../components/energy-generated-at-plant/energy-generated-at-plant';
import {EmissionReductionInterface} from '../../components/emissions-reduction/interfaces/emission-reduction.interface';
import {
  EnergyGeneratedAtPlantInterface
} from '../../components/energy-generated-at-plant/interfaces/energy-generated-at-plant.interface';
import EnergyGeneratedAtPlantFactory
  from '../../components/energy-generated-at-plant/energy-generated-at-plant.factory';
import EnergyGeneratedAtPlantSummaryLastPeriodsInterface
  from '../../components/energy-generated-at-plant/interfaces/energy-generated-at-plant-summary-last-periods.interface';
import {
  handleLastPeriodsPercentage
} from '../../shared/services/gia.service';
import PlantInfo from '../../components/plant-info/plant-info';
import {PlantInfoInterface} from '../../components/plant-info/plant-info.interface';
import {loadPlantDetails, loadPlantDetailsData, loadPlantDetailsEmissionsReduction} from './_requests';
import Skeleton from 'react-loading-skeleton';
import ShutdownPlant from '../../components/shutdown-plant/shutdown-plant';

interface PlantProps { }

const handlePlantDetails = (res) => {
  return {
    id: res.plant_id,
    name: res.name,
    integrator: res.integrator,
    peakPower: res.peak_power ? +res.peak_power : 0,
    city: res.city,
    state: res.uf,
    dataLoggerSerialNumber: res.datalogger_sn,
    deviceSerialNumber: res.device_sn,
    manufacturer: res.manufacturer,
    weather: {
      temperature: res.weather_temp,
      icon: res.weather_icon,
    }
  } as PlantInfoInterface;
};

const Plant: FC<PlantProps> = () => {

  let { plantId } = useParams();

  const [plant, setPlant] = useState<PlantInfoInterface>({} as PlantInfoInterface);
  const [emissionsReduction, setEmissionsReduction] = useState<EmissionReductionInterface>({} as EmissionReductionInterface);
  const [energyGeneratedAtPlant, setEnergyGeneratedAtPlant] = useState<EnergyGeneratedAtPlantInterface>(EnergyGeneratedAtPlantFactory.makeInitialStateEnergyGeneratedAtPlant());
  const [lastPeriodsPercentage, setLastPeriodsPercentage] = useState<EnergyGeneratedAtPlantSummaryLastPeriodsInterface>();
  const [filter, setFilter] = useState<string>('today');
  const [isLoadingPlantInfo, setIsLoadingPlantInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await loadPlantDetails(plantId).then(r => r.data).then(r => r[0]).then(response => {
        const plantDetails = handlePlantDetails(response);
        const lastPeriodsPercentage = handleLastPeriodsPercentage(response);
        setPlant(plantDetails);
        setLastPeriodsPercentage(lastPeriodsPercentage);
      });
    }
    setIsLoading(true);
    setIsLoadingPlantInfo(true);
    fetchData().then(() => setIsLoadingPlantInfo(false)).catch(error => {
      setIsLoadingPlantInfo(false);
      console.error(error);
    });
  }, [plantId]);

  useEffect(() => {
    const reloadData = (period: any) => {
      const fetchData = async () => {
        const energyGeneratedAtPlantUpdate = await loadPlantDetailsData(plantId, period);
        const emissionsReduction = await loadPlantDetailsEmissionsReduction(plantId, period);
        energyGeneratedAtPlantUpdate.summary.lastPeriodsPercentage = lastPeriodsPercentage as EnergyGeneratedAtPlantSummaryLastPeriodsInterface;
        setEnergyGeneratedAtPlant(energyGeneratedAtPlantUpdate);
        setEmissionsReduction(emissionsReduction);
      }
      setIsLoading(true);
      fetchData().then(() => setIsLoading(false)).catch(error => {
        setIsLoading(false);
        console.error(error);
      });
    }

    /* The Emission Reduction block depends on calculated data from energy generated block */
    if (lastPeriodsPercentage && filter) {
      reloadData(filter);
    }
  }, [plantId, lastPeriodsPercentage, filter]);

  const onChangeFilter = (filter) => {
    if (filter) {
      setFilter(filter);
    }
  };

  return (
    <div className={styles.Plant} data-testid="Plant">
      {isLoadingPlantInfo && <Skeleton height={82} />}
      {!isLoadingPlantInfo && <PlantInfo plantInfo={plant}></PlantInfo>}

      {isLoading && <Skeleton height={127} />}
      {!isLoading && <EmissionsReduction emissionReduction={emissionsReduction} />}
      <EnergyGeneratedAtPlant onChangeFilter={onChangeFilter} energyGeneratedAtPlant={energyGeneratedAtPlant} isLoading={isLoading} />
      {!isLoading && <ShutdownPlant plantId={plant.id} />}

    </div>
  );
}


export default Plant;
